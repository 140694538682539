<template>
  <div>
    <a-layout id="components-layout-demo-top-side-2">
      <a-layout-header class="header ">
        <div class="w1200 flexbetw">
          <div class="img">
            <img
              src="~@/image/logo.png"
              alt="logo"
            />
          </div>
          <!-- <div class="tabHeight">
            <a-tabs
              default-active-key=""
              @change="callback"
            >
              <a-tab-pane
                :tab="item.tabName"
                v-for="item in tabData"
                :key="item.id"
              ></a-tab-pane>
                </a-tabs>
          </div> -->
          <div class="tabHeight">
            <div @click="callback(1)">
              网站首页
            </div>
            <div @click="callback(2)">
              业务范围
            </div>
            <div @click="callback(3)">
              关于我们
            </div>
            <div @click="callback(4)">
              联系我们
            </div>
          </div>
        </div>
      </a-layout-header>
      <a-layout>
        <div class="w1200 layout_list">
          <a-layout-sider
            width="180"
            style="background: #fff"
          >
            <div class="logo">
              <img
                src="~@/image/usertitle.png"
                alt=""
                srcset=""
              >
            </div>
            <div class="logoImg">陪妳看最美的风景</div>

            <!-- <a-menu
          mode="inline"
          :default-selected-keys="['1']"
          :default-open-keys="['1']"
          :style="{ height: '100%', borderRight: 0 }"
         
        >
     
          <a-sub-menu  v-for="(item,i) in leftData" :key="i">
            <span slot="title" class="title">{{item.name}}</span>
            <a-menu-item @click="clickChanged(item1)"  v-for="(item1,i1) in item.children" :key="i1"  :title="item1.name">
              {{item1.name}}
            </a-menu-item>
          </a-sub-menu>  
        </a-menu> -->
            <a-menu
              style="width: 180px"
              :default-selected-keys="['1']"
              :default-open-keys="['sub1','sub2','sub1-2']"
              mode="inline"
              :selected-keys="[current]"
              @click="handleClick"
            >
              <a-sub-menu key="sub1">
                <span slot="title">

                  <span class="titleH1">代叔信息</span>
                </span>
                <a-sub-menu
                  key="sub1-2"
                  title="出入仓"
                >
                  <a-menu-item
                    @click="clickGoChanged(1)"
                    key="1"
                  >
                    入仓
                    </a-menu-item>
                    <a-menu-item
                      key="2"
                      @click="clickGoChanged(2)"
                    >
                      出仓
                      </a-menu-item>
              </a-sub-menu>
              <a-menu-item key="3">
                入仓预约
              </a-menu-item>
              <a-menu-item key="4">
                库存查询
              </a-menu-item>
              <a-menu-item key="5">
                订单记录
              </a-menu-item>
              <a-menu-item key="6">
                物流信息
              </a-menu-item>
              <a-menu-item key="7">
                结算账单
              </a-menu-item>
              <a-menu-item key="8">
                我的钱包
              </a-menu-item>
              <a-menu-item key="9">
                我的预算
              </a-menu-item>
              </a-sub-menu>
              <a-sub-menu key="sub2">
                <span slot="title">

                  <span class="titleH1">工具与服务</span>
                </span>
                <a-menu-item key="10">
                  代叔集贸仓
                </a-menu-item>
                <a-menu-item key="11">
                  代叔集配
                </a-menu-item>
                <a-menu-item
                  key="12"
                  @click="clickGoChanged(12)"
                >
                  代叔C仓
                  </a-menu-item>
                  <a-menu-item key="13">
                    仓票
                  </a-menu-item>
                  <a-menu-item key="14">
                    常用地址
                  </a-menu-item>
                  <a-menu-item key="15">
                    联系我们
                  </a-menu-item>
                  <a-menu-item key="16">
                    司机验证
                  </a-menu-item>
                  <a-menu-item
                    @click="clickGoChanged(17)"
                    key="17"
                  >
                    个人中心
                    </a-menu-item>
              </a-sub-menu>
              </a-menu>
              </a-layout-sider>
              <a-layout style="padding:0 25px 25px">

                <a-layout-content :style="{ background: '#fff', margin: 0, minHeight: '280px' }">
                  <router-view />

                </a-layout-content>
              </a-layout>
        </div>

      </a-layout>
    </a-layout>

    <Footer />

  </div>

</template>
<script>
import Footer from "@/components/footer/footer.vue";
import "@/assets/comm/comm.css";
import { setItem, getItem } from "../../tools/tools.js";
export default {
  data() {
    return {
      collapsed: false,
      current: '1',
      tabData: [
        {
          id: 1,
          tabName: "网站首页"
        },
        {
          id: 2,
          tabName: "业务范围"
        },
        {
          id: 3,
          tabName: "关于我们"
        },
        {
          id: 4,
          tabName: "联系我们"
        }
      ],
      keyValue: "",
      leftData: [
        {
          name: "代叔信息",
          children: [
            {
              name: "入仓",
              router: "/sharedWarehouse/inWarehousing"
            },
            {
              name: "出仓",
              router: "/sharedWarehouse/outWarehousing"
            },
            {
              name: "入仓预约"
            },
            {
              name: "库存查询"
            },
            {
              name: "订单记录"
            },
            {
              name: "物流信息"
            },
            {
              name: "结算账单"
            },
            {
              name: "我的钱包"
            },
            {
              name: "我的预算"
            }
          ]
        },
        {
          name: "工具与服务",
          children: [
            {
              name: "代叔集贸仓"
            },
            {
              name: "代叔集配"
            },
            {
              name: "代叔C仓"
            },
            {
              name: "仓票",
              router: "/sharedWarehouse/warehouseTicket"
            },
            {
              name: "常用地址"
            },
            {
              name: "联系我们"
            },
            {
              name: "司机验证"
            },
            {
              name: "个人中心",
              router: "/sharedWarehouse/personalCenter"
            }
          ]
        }
      ],
      keyId: "",
      default:1
    };
  },
  methods: {
    callback(key) {
      if (key == 1) {
        this.goRouterSession(key);
      } else if (key == 2) {
        this.goRouterSession(key);
      } else if (key == 3) {
        this.goRouterSession(key);
      } else {
        this.goRouterSession(key);
      }
    },
    // key存储
    goRouterSession(key) {
      window.sessionStorage.setItem("keyTab", JSON.stringify(key));
      this.$router.push("/");
    },
    clickChanged(item1) {
      if (item1.router) {
        this.$router.push(item1.router);
      }
    },
    clickGoChanged(id) {
      if (id == 1) {
        this.$router.push("/sharedWarehouse/inWarehousing");
      } 
      //  if (id == 2) {
      //   this.$router.push("/sharedWarehouse/outWarehousing");
      // } 
      if (id == 12) {
        this.$router.push("/sharedWarehouse/warehouseTicket");
      } 
      if(id==17) {
        this.$router.push("/sharedWarehouse/personalCenter");
      }
    },
    showModal() {
      this.visible = true;
    },
    handleClick(e) {
      console.log("click ", e);
      this.current = e.key;
    }
    // changeTheme(checked) {
    //   this.theme = checked ? "dark" : "light";
    // }
  },
  created() {
    this.keyId = this.$route.query.id;
    this.current = JSON.parse(window.sessionStorage.getItem("index") || {})+'';
    console.log(this.current, "this.$router.id");
    if(this.current==1) {
      this.$router.push("/sharedWarehouse/inWarehousing");
    }
    if (this.current==2) {
       this.$router.push("/sharedWarehouse/warehouseTicket");
    }
  },
  components: {
    Footer
  }
};
</script>

<style  lang="less" scoped>
#components-layout-demo-top-side-2 {
  /deep/.ant-layout-sider-children {
    .logo {
      margin: 0;
      margin: 35px 37px 9px 38px;
      border-radius: 50%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: pink;
      img {
        height: 58px;
        width: 58px;
        position: absolute;
      }
    }
    .logoImg {
      margin-top: 40px;
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
    }
  }
  height: 1250px;
  .ant-layout-header {
    background-color: #fff;
    height: 104px;
    padding: 0;
    line-height: normal;
  }
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    .tabHeight {
      .ant-tabs {
        /deep/.ant-tabs-bar {
          border-bottom: 0;
        }
      }
    }
  }
}
.layout_list {
  display: flex;
}
.ant-layout {
  margin-top: 21px;
  display: flex;
  justify-content: center;
}
.title {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}
.ant-menu-submenu-selected,
.ant-menu-item-selected {
  color: #fd4d02;
  font-family: Source Han Sans CN;
  font-weight: bold;
}
.ant-menu-item-active {
  color: #fd4d02;
  font-family: Source Han Sans CN;
  font-weight: bold;
}

.ant-menu-inline /deep/ .ant-menu-submenu .ant-menu-submenu-active {
  color: #fd4d02;
  font-family: Source Han Sans CN;
  font-weight: bold;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #fd4d02;
}
.layout_list {
  .ant-layout {
    margin-top: 0px;
  }
}
.header {
  .tabHeight {
    /deep/.ant-tabs-tab:hover {
      color: #fd4d02;
    }
  }
}
.titleH1 {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
}
.tabHeight {
  display: flex;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #525252;
  div {
    margin-right: 42px;
    cursor: pointer;
     height: 34px;
  }
  div:hover {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #ff4d00;
    border-bottom: 2px solid #FF4D00;
    height: 34px;
  }
}
</style>
